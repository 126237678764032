<template>
  <div>
    <div class="--row">
      <div>
        <h1 class="text--secondary">
          Business Categories <span class="red--text">*</span>
        </h1>
        <p>Culinary categories hint</p>
      </div>
      <v-autocomplete
        label="Select the categories for your hospitality"
        v-model="shop_cuisines"
        :items="sort_by_attr(cuisines, 'text')"
        class="required"
        multiple
        outlined
        dense
        flat
        clearable
        hint="Please note: You can only choose up to 4 main categories for your business."
        persistent-hint
        :rules="rules_cuisines"
        :class="{ 'input-highlight': !shop_cuisines.length }"
        @input="$emit('change', true)"
        required
      ></v-autocomplete>
    </div>
    <div class="--row">
      <div>
        <h1 class="text--secondary">
          Dietary Offerings <span class="red--text">*</span>
        </h1>
        <p>Dietary Offerings hint</p>
      </div>

      <v-select
        :items="sort_by_attr(dietary_types, 'text')"
        label="Select the dietary options for your hospitality"
        hint="Please note: Only pick the dietary options if you specially cater to those dietary requirements. You can choose up to 3."
        v-model="shop_dietary"
        class="required"
        :class="{ 'input-highlight': !shop_dietary.length }"
        multiple
        persistent-hint
        outlined
        dense
        flat
        clearable
        required
        :rules="rules_dietary"
        @input="$emit('change', true)"
      ></v-select>
    </div>
    <div class="--row">
      <div>
        <h1 class="text--secondary">
          Pricing Guide <span class="red--text">*</span>
        </h1>
        <p>Pricing Guide hint</p>
      </div>

      <v-select
        autocomplete="off"
        outlined
        dense
        flat
        class="required"
        clearable
        :rules="[field_req]"
        label="Pricing Guide"
        v-model="value.hospitality_pricing_guide"
        :class="{ 'input-highlight': !value.hospitality_pricing_guide }"
        :items="pricing_guide_items"
        item-text="text"
        item-value="value"
        @input="$emit('change', true)"
      />
    </div>
    <div class="--row">
      <div>
        <h1 class="text--secondary">Email <span class="red--text">*</span></h1>
        <p>Your business email adress.</p>
      </div>
      <v-text-field
        label="Email"
        v-model="value.email"
        :rules="rules_email"
        :class="{ 'input-highlight': !value.email }"
        autocomplete="off"
        outlined
        dense
        flat
        clearable
        class="required"
        @input="$emit('change', true)"
      />
    </div>
    <div class="--row">
      <div>
        <h1 class="text--secondary">
          Main Phone Number <span class="red--text">*</span>
        </h1>
        <p>Phone number hint</p>
      </div>
      <v-text-field
        label="Main Phone Number"
        class="required"
        :class="{ 'input-highlight': !value.mobile_no }"
        v-model="value.mobile_no"
        :rules="rules_phone"
        autocomplete="off"
        outlined
        dense
        flat
        clearable
        @input="$emit('change', true)"
      />
    </div>
    <div class="--row">
      <div>
        <h1 class="text--secondary">Secondary Phone Number</h1>
        <p>Hint please</p>
      </div>
      <v-text-field
        label="Phone Number"
        v-model="value.phone_no"
        :rules="rules_phone"
        autocomplete="off"
        outlined
        dense
        flat
        clearable
        @input="$emit('change', true)"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { VModelObjectMixin } from "@/components/mixins/InputMixins";
import {
  field_req,
  valid_email,
  valid_phone,
  maximum_array_length,
  minimum_array_length,
} from "@/utils/form_val_rules";
import { sort_by_attr } from "@/utils/utils.js";
import { DIETARY_TYPES } from "@/shared/const";

export default {
  name: "BusinessGeneralSettings",
  mixins: [VModelObjectMixin],
  data() {
    return {
      field_req,
      sort_by_attr,
      rules_cuisines: [
        (v) => minimum_array_length(v, 1),
        (v) => maximum_array_length(v, 4),
      ],
      rules_dietary: [
        (v) => minimum_array_length(v, 1),
        (v) => maximum_array_length(v, 3),
      ],
      rules_email: [field_req, valid_email],
      rules_phone: [valid_phone],
      pricing_guide_items: [
        { value: 1, text: "$" },
        { value: 2, text: "$$" },
        { value: 3, text: "$$$" },
      ],
    };
  },
  computed: {
    ...mapState("AdminStore", ["hospitality_cuisines"]),
    cuisines() {
      return Object.keys(this.hospitality_cuisines).map((key) => {
        return { text: this.hospitality_cuisines[key], value: key };
      });
    },
    dietary_types() {
      return Object.keys(DIETARY_TYPES).map((key) => {
        return { text: DIETARY_TYPES[key], value: key };
      });
    },
    shop_cuisines: {
      get() {
        if (!this.value?.cuisines) return [];
        return Object.keys(this.value.cuisines);
      },
      set(value) {
        this.value.cuisines = value.reduce((obj, cuisine) => {
          return {
            ...obj,
            [cuisine]: this.hospitality_cuisines[cuisine],
          };
        }, {});
      },
    },
    shop_dietary: {
      get() {
        if (!this.value?.dietary) return [];
        return Object.keys(this.value.dietary);
      },
      set(value) {
        this.value.dietary = value.reduce((obj, option) => {
          return {
            ...obj,
            [option]: DIETARY_TYPES[option],
          };
        }, {});
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.--row {
  @media #{map-get($display-breakpoints, 'md-and-up')} {
    padding-bottom: 2rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 2rem;
    align-items: center;
    grid-template-areas: "left right";
  }
  &:last-of-type {
    padding-bottom: 0;
  }
  div {
    grid-area: left;
    p {
      margin-bottom: 0;
    }
  }
  .v-input {
    grid-area: right;
  }
}
</style>
