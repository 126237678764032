export function is_empty_object(obj) {
  return Object.entries(obj).length === 0;
}

export function is_valid_uk_postcode(postcode) {
  const uk_postcode_regex = /^[A-Z]{1,2}\d[A-Z\d]? ?\d[A-Z]{2}$/i;
  return postcode.match(uk_postcode_regex);
}

export function format_decimal_or_int(number) {
  if (typeof number !== "number" || number % 1 === 0) return number;
  return number.toFixed(2);
}

export function truncate_string_with_ellipses(string, length) {
  return `${string.substring(0, length - 3)}...`;
}

export function sort_by_attr(arr, attr) {
  return arr.sort(function (a, b) {
    var text_a = a[attr].toUpperCase();
    var text_b = b[attr].toUpperCase();
    return text_a < text_b ? -1 : text_a > text_b ? 1 : 0;
  });
}

export function title_case(string) {
  return string
    .toLowerCase()
    .replace(/_/g, " ")
    .replace(/\b\w/g, (key) => key.toUpperCase());
}

export function handle_numeric_input(event) {
  if (["e", "E", "+", "-"].includes(event.key)) event.preventDefault();
}
