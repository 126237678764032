export const VModelObjectMixin = {
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  watch: {
    value: {
      deep: true,
      handler() {
        this.$emit("input", this.value);
      },
    },
  },
};
