<template>
  <div>
    <v-form
      class="form py-7 px-8"
      v-model="business_form_valid"
      v-if="firebase_shop_copy"
    >
      <p class="mb-6">
        Fill out your business informations to help customers to reach you out.
      </p>
      <BusinessGeneralSettings
        v-model="firebase_shop_copy"
        @change="$emit('change', true)"
      />
    </v-form>
    <v-divider class="mb-2" />
    <v-form class="form py-7 px-8" v-model="form_valid">
      <p class="mb-6">
        You must fully complete the below settings in order to set up your
        booking calendar.
      </p>
      <div class="--row">
        <div>
          <h1 class="text--secondary">
            Time Slot Intervals <span class="red--text">*</span>
          </h1>

          <p class="mb-2">
            This indicates how the hour is divided up in your booking calendar.
          </p>
          <p>
            <b
              ><v-icon color="red" class="mr-2">mdi-alert-box-outline</v-icon>If
              you are changing the interval value, make sure to set up excluded
              times as they will be reset. You can do it
              <router-link :to="{ name: 'Booking Hours' }">here</router-link
              >.</b
            >
          </p>
        </div>
        <v-select
          autocomplete="off"
          outlined
          dense
          flat
          class="required"
          clearable
          :rules="[field_req]"
          label="Time Slot Intervals"
          v-model="settings.interval"
          :items="INTERVAL_OPTIONS"
          :class="{ 'input-highlight': !settings.interval }"
          @input="$emit('change', true)"
        />
      </div>
      <div class="--row">
        <div>
          <h1 class="text--secondary">Maximum guests per arrival</h1>
          <p>
            Set the maximum number of guests per
            {{ ONE_HOUR[settings.interval] || "arrival" }} interval. Leave empty
            to not limit.
          </p>
        </div>
        <v-text-field
          autocomplete="off"
          outlined
          flat
          dense
          clearable
          label="Maximum bookings per arrival (number)"
          v-model="settings.max_guests_per_arrival_time"
          :rules="[val_min_0]"
          type="number"
          step="1"
          min="0"
          @input="$emit('change', true)"
        />
      </div>
      <div class="--row">
        <div>
          <h1 class="text--secondary">
            Duration Per Booking <span class="red--text">*</span>
          </h1>
          <p>
            Set how long you allow your customers to book a table before they
            have to return it.
          </p>
        </div>
        <section>
          <v-select
            autocomplete="off"
            outlined
            dense
            flat
            class="required"
            clearable
            :rules="[field_req]"
            label="Duration Per Booking"
            v-model="settings.duration"
            :class="{ 'input-highlight': !settings.duration }"
            :items="DURATION_OPTIONS"
            @input="$emit('change', true)"
          />
          <v-checkbox
            dense
            label="Set different duration per day"
            v-model="has_different_duration_per_day"
            @change="
              (value) => {
                !value ? (settings.duration_per_day = {}) : null;
                $emit('change', true);
              }
            "
          />
          <div v-if="has_different_duration_per_day">
            <v-select
              v-for="{ day_no, text } in days"
              :key="day_no"
              autocomplete="off"
              dense
              flat
              clearable
              v-model="settings.duration_per_day[day_no]"
              :label="`Duration on ${text}`"
              :items="DURATION_OPTIONS"
              @input="$emit('change', true)"
            />
          </div>
        </section>
      </div>
      <div class="--row">
        <div>
          <h1 class="text--secondary">
            Table Turnaround Time <span class="red--text">*</span>
          </h1>
          <p>
            Set a turnaround time you may need in between bookings. Must be
            divisible by the interval.
          </p>
        </div>
        <v-select
          autocomplete="off"
          outlined
          dense
          flat
          class="required"
          clearable
          :rules="[field_req]"
          label="Table Turnaround Time (Minutes)"
          v-model="settings.turnaround_time"
          :class="{ 'input-highlight': settings.turnaround_time === null }"
          :items="
            settings.interval
              ? [
                  { text: 'No turnaround', value: 0 },
                  ...INTERVAL_OPTIONS.filter(
                    (option) => !(option.value % settings.interval)
                  ),
                ]
              : [{ text: 'No turnaround', value: 0 }, ...INTERVAL_OPTIONS]
          "
          @input="$emit('change', true)"
        />
      </div>
      <!-- <v-alert
      type="info"
      class="mb-12"
      outlined
      color="primary"
      v-if="bookings_yield"
    >
      <span class="dark-grey--text"
        >Selected <b>duration</b> and <b>turnaround</b> time settings allows for
        {{ bookings_yield }} bookings in 8 hours shift per table</span
      >
    </v-alert> -->
      <div class="--row">
        <div>
          <h1 class="text--secondary">
            Minimum Booking Time <span class="red--text">*</span>
          </h1>
          <p>
            Set the minimum time you allow your customers to make a table
            booking.
          </p>
        </div>
        <v-select
          autocomplete="off"
          outlined
          dense
          flat
          class="required"
          clearable
          :rules="[field_req]"
          label="Minimum Booking Time"
          v-model="settings.min_notice"
          :class="{ 'input-highlight': !settings.min_notice }"
          :items="MINIMUM_NOTICE_OPTIONS"
          @input="$emit('change', true)"
        />
      </div>
      <div class="--row">
        <div>
          <h1 class="text--secondary">
            Maximum Booking Time <span class="red--text">*</span>
          </h1>
          <p>
            Set how far in advance you allow your customers to make a table
            booking.
          </p>
        </div>
        <v-select
          autocomplete="off"
          outlined
          dense
          flat
          class="required"
          clearable
          :rules="[field_req]"
          label="Maximum Booking Time (minutes)"
          :class="{ 'input-highlight': !settings.max_notice }"
          v-model="settings.max_notice"
          :items="MAX_NOTICE_OPTIONS"
          @input="$emit('change', true)"
        />
      </div>

      <div class="--row">
        <div>
          <h1 class="text--secondary">
            Maximum Empty Seats <span class="red--text">*</span>
          </h1>
          <p>Set the maximum number of empty seats you allow on a table.</p>
        </div>
        <v-text-field
          autocomplete="off"
          outlined
          dense
          flat
          class="required"
          clearable
          :rules="[field_req, val_min_0]"
          label="Maximum Empty Seats (number)"
          v-model="settings.empty_seats"
          :class="{ 'input-highlight': !settings.empty_seats }"
          type="number"
          step="1"
          min="0"
          @input="$emit('change', true)"
        />
      </div>
      <div class="--row">
        <div>
          <h1 class="text--secondary">Use group bookings</h1>
          <p>
            Allow staff members to create group bookings which can occupy
            multiple tables.
          </p>
        </div>
        <section>
          <v-checkbox
            label="Use group bookings"
            v-model="settings.use_group_bookings"
            @change="$emit('change', true)"
          />
          <template v-if="settings.use_group_bookings">
            <v-text-field
              autocomplete="off"
              outlined
              dense
              flat
              clearable
              label="Group booking message"
              v-model="primary_message"
              hint='Message displayed in the client app, eg. "For group bookings give us a call"'
              type="text"
              @blur="on_primary_message_change"
            />
          </template>
        </section>
      </div>

      <div class="--row">
        <div>
          <h1 class="text--secondary">Use ending time</h1>
          <p>Inform customer about ending time of a reservation.</p>
        </div>
        <v-checkbox
          dense
          label="Use ending time"
          v-model="settings.use_ending_time"
          @change="$emit('change', true)"
        />
      </div>
      <div class="--row">
        <div>
          <h1 class="text--secondary">Allow cancellation</h1>
          <p>Allow customer to cancel reservation.</p>
        </div>
        <v-checkbox
          dense
          label="Allow cancellation"
          v-model="settings.allow_cancellation"
          @change="$emit('change', true)"
        />
      </div>
      <div class="--row">
        <div>
          <h1 class="text--secondary">Collect deposit</h1>
          <p>
            Capture payment details for a booking deposit and capture it
            immediately or later.
          </p>
        </div>
        <section>
          <v-checkbox
            label="Collect deposit"
            v-model="settings.collect_deposit"
            @change="$emit('change', true)"
          />
          <template v-if="settings.collect_deposit">
            <v-checkbox
              dense
              label="Save customer card details, collect deposit manually."
              v-model="settings.hold_deposit_payment"
              @change="$emit('change', true)"
              class="mb-4"
            />
            <v-text-field
              autocomplete="off"
              outlined
              dense
              flat
              clearable
              label="Before checkout message"
              v-model="before_checkout_message"
              type="text"
              @blur="on_before_checkout_message_change"
            />
            <v-text-field
              autocomplete="off"
              outlined
              dense
              flat
              clearable
              label="Deposit amount (£)"
              v-model="settings.deposit_amount"
              class="required"
              :rules="[field_req, val_min_0]"
              type="number"
              step="0.1"
              min="1"
              @input="$emit('change', true)"
            />
          </template>
        </section>
      </div>
      <div class="--row" v-if="false">
        <div>
          <h1 class="text--secondary">Allow table selection</h1>
          <p>Allow customer to select table for reservation</p>
        </div>
        <v-checkbox
          dense
          label="Allow table selection"
          v-model="settings.allow_choose_table"
          @change="$emit('change', true)"
        />
      </div>
      <div class="--row" v-if="false">
        <div>
          <h1 class="text--secondary">Minimum guests</h1>
          <p>Set the minimum number of people required for a reservation</p>
        </div>
        <v-text-field
          autocomplete="off"
          outlined
          flat
          dense
          clearable
          label="Minimum guests (number)"
          v-model="settings.min_guests"
          :rules="[val_min_0]"
          type="number"
          step="1"
          min="0"
          @input="$emit('change', true)"
        />
      </div>
      <div class="--row" v-if="false">
        <div>
          <h1 class="text--secondary">Maximum guests</h1>
          <p>Set the maximum number of people per reservation</p>
        </div>
        <v-text-field
          autocomplete="off"
          outlined
          flat
          dense
          clearable
          label="Maximum guests (number)"
          v-model="settings.max_guests"
          :rules="[val_min_0]"
          type="number"
          step="1"
          min="0"
          @input="$emit('change', true)"
        />
      </div>
      <div class="--row" v-if="false">
        <div>
          <h1 class="text--secondary">Maximum capacity</h1>
          <p>Set the maximum number of people</p>
        </div>
        <v-text-field
          autocomplete="off"
          outlined
          flat
          dense
          clearable
          label="Maximum capacity (number)"
          v-model="settings.max_capacity"
          :rules="[val_min_0]"
          type="number"
          step="1"
          min="0"
          @input="$emit('change', true)"
        />
      </div>
    </v-form>
  </div>
</template>

<script>
import firebase from "firebase/app";
import { mapState, mapActions, mapGetters } from "vuex";

import { db } from "@/db";
import { upsert_service } from "@/requests";

import { field_req, val_min_0 } from "@/utils/form_val_rules";

import BusinessGeneralSettings from "../components/BusinessGeneralSettings";

const five_min = 5;
const fifteen_min = 3 * five_min;
const hour = 4 * fifteen_min;
const day = 24 * hour;
const week = 7 * day;
const month = 30 * day; //rounded
const year = 12 * month;

const DURATION_OPTIONS = [
  { text: "15 min", value: fifteen_min },
  { text: "30 min", value: 2 * fifteen_min },
  { text: "45 min", value: 3 * fifteen_min },
  { text: "1 hour", value: hour },
  { text: "1¼ hour", value: 1.25 * hour },
  { text: "1½ hour", value: 1.5 * hour },
  { text: "1¾ hour", value: 1.75 * hour },
  { text: "2 hour", value: 2 * hour },
  { text: "2½ hour", value: 2.5 * hour },
  { text: "3 hour", value: 3 * hour },
  { text: "3½ hour", value: 3.5 * hour },
  { text: "4 hours", value: 4 * hour },
  { text: "5 hours", value: 5 * hour },
  { text: "6 hours", value: 6 * hour },
  { text: "8 hours", value: 8 * hour },
];

const MINIMUM_NOTICE_OPTIONS = [
  ...DURATION_OPTIONS,
  { text: "10 hours", value: 10 * hour },
  { text: "12 hours", value: 12 * hour },
  { text: "24 hours", value: day },
  { text: "48 hours", value: 2 * day },
  { text: "3 days", value: 3 * day },
  { text: "5 days", value: 3 * day },
  { text: "7 days", value: week },
  { text: "14 days", value: 2 * week },
];

const MAX_NOTICE_OPTIONS = [
  { text: "24 hours", value: day },
  { text: "2 days", value: 2 * day },
  { text: "3 days", value: 3 * day },
  { text: "5 days", value: 3 * day },
  { text: "1 week", value: week },
  { text: "2 weeks", value: 2 * week },
  { text: "3 weeks", value: 3 * week },
  { text: "4 weeks", value: 4 * week },
  { text: "5 weeks", value: 5 * week },
  { text: "6 weeks", value: 6 * week },
  { text: "2 months", value: 2 * month },
  { text: "3 months", value: 3 * month },
  { text: "4 months", value: 4 * month },
  { text: "5 months", value: 5 * month },
  { text: "6 months", value: 6 * month },
  { text: "1 year", value: year },
];

const ONE_HOUR = {
  5: "5 min",
  10: "10 min",
  15: "15 min",
  20: "20 min",
  30: "30 min",
  45: "45 min",
  60: "1 hour",
};
const INTERVAL_OPTIONS = [
  { text: "5 min", value: five_min },
  { text: "10 min", value: 2 * five_min },
  { text: "15 min", value: fifteen_min },
  { text: "20 min", value: fifteen_min + five_min },
  { text: "30 min", value: 2 * fifteen_min },
  { text: "1 hour", value: hour },
];

export default {
  name: "BookingSettingsGeneral",
  props: {
    executeUpdate: { type: Boolean, default: false },
  },
  components: { BusinessGeneralSettings },
  data() {
    return {
      DURATION_OPTIONS,
      MINIMUM_NOTICE_OPTIONS,
      MAX_NOTICE_OPTIONS,
      INTERVAL_OPTIONS,
      ONE_HOUR,
      field_req,
      val_min_0,
      form_valid: false,
      business_form_valid: false,
      has_different_duration_per_day: false,
      firebase_shop_copy: null,
      days: [
        { day_no: 1, text: "Monday" },
        { day_no: 2, text: "Tuesday" },
        { day_no: 3, text: "Wednesday" },
        { day_no: 4, text: "Thursday" },
        { day_no: 5, text: "Friday" },
        { day_no: 6, text: "Saturday" },
        { day_no: 7, text: "Sunday" },
      ],
      before_checkout_message: null,
      primary_message: null,
      settings: {
        // Required fields
        duration: null,
        min_notice: null,
        max_notice: null,
        interval: null,
        empty_seats: null,
        turnaround_time: null,

        // Optional fields
        duration_per_day: {
          1: null,
          2: null,
          3: null,
          4: null,
          5: null,
          6: null,
          7: null,
        },
        use_group_bookings: false,
        use_ending_time: false,
        allow_cancellation: false,
        collect_deposit: false,
        hold_deposit_payment: false,
        deposit_amount: null,
        allow_choose_table: false,
        min_guests: null,
        max_guests: null,
        max_booking_per_arrival_time: null,
        max_guests_per_arrival_time: null,
        max_capacity: null,
      },
    };
  },
  computed: {
    ...mapState("AdminStore", ["shop_id", "shop"]),
    ...mapState("TableBookingStore", {
      fetched_settings: "settings",
    }),
    ...mapGetters("ShopStore", ["table_booking"]),
    ...mapGetters("TableBookingStore", ["booking_settings_state"]),
    bookings_yield() {
      const turnaround = this.settings.turnaround_time;
      const duration = this.settings.duration;

      if (turnaround == null || !duration) return;
      let bookings = 0;
      let time = 8 * hour;
      while (time >= duration) {
        time -= duration;
        time -= turnaround;
        bookings += 1;
      }

      return bookings;
    },
  },
  async mounted() {
    this.$emit("loading", true);
    this.firebase_shop_copy = { ...this.shop };
    this.before_checkout_message = this.table_booking.before_checkout_message;
    this.primary_message = this.table_booking.primary_message;

    try {
      await this.get_settings(this.shop_id);
      if (this.fetched_settings && Object.keys(this.fetched_settings).length)
        this.settings = this.fetched_settings;

      this.has_different_duration_per_day = Object.values(
        this.settings.duration_per_day
      ).some((duration) => duration != null);
      setTimeout(() => {
        this.$emit("change", false);
      }, 100);
    } catch (error) {
      console.error(error);
    } finally {
      this.$emit("loading", false);
    }
  },
  watch: {
    "$data.settings.interval": {
      handler: function (interval) {
        if (!this.settings.turnaround_time) return;
        if (this.settings.turnaround_time % interval)
          this.settings.turnaround_time = null;
      },
    },
    form_valid(is_valid) {
      this.$emit("form-valid", is_valid && this.business_form_valid);
    },
    business_form_valid(is_valid) {
      this.$emit("form-valid", is_valid && this.form_valid);
    },
    async executeUpdate(execute) {
      if (!execute) return;
      await this.do_update();
    },
  },
  methods: {
    async do_firebase_shop_update() {
      return db
        .collection("shops")
        .doc(this.shop_id)
        .update({
          ...this.firebase_shop_copy,
          updated_at: firebase.firestore.FieldValue.serverTimestamp(),
        });
    },
    ...mapActions("TableBookingStore", [
      "get_settings",
      "upsert_booking_settings",
    ]),
    ...mapActions("ShopStore", ["get_shop"]),
    async do_update() {
      try {
        await this.upsert_booking_settings({
          shop_id: this.shop_id,
          updated_settings: this.settings,
        });
        await this.do_firebase_shop_update();
        // await this.$set(settings, "settings");
        this.settings = this.fetched_settings;
        this.$emit("change", false);
        this.$emit("show-snackbar");
      } catch (error) {
        console.error(error);
      } finally {
        this.$emit("update:finished");
      }
    },
    async on_before_checkout_message_change() {
      try {
        await upsert_service({
          ...this.table_booking,
          before_checkout_message: this.before_checkout_message,
        });
        await this.get_shop(this.shop.id);
      } catch (error) {
        console.error(error);
      }
    },
    async on_primary_message_change() {
      try {
        await upsert_service({
          ...this.table_booking,
          primary_message: this.primary_message,
        });
        await this.get_shop(this.shop.id);
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.form {
  width: 100%;
  .--row {
    @media #{map-get($display-breakpoints, 'md-and-up')} {
      padding-bottom: 2rem;
      display: grid;
      grid-template-columns: 1fr 1fr;
      column-gap: 2rem;
      // align-items: start;
      grid-template-areas: "left right";
    }
    &:last-of-type {
      padding-bottom: 0;
    }
    div {
      grid-area: left;
      p {
        margin-bottom: 0;
      }
    }
    .v-input {
      grid-area: right;
    }
  }
}
</style>
